import React from 'react';
// import { css } from 'linaria';

// const root = css`
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     display: flex;
//     flex-direction: column;
// `;

const Root = ({ children }) => {
  return(
    <>
        <div>
            {children}
        </div>
    </>
  )
};
export default Root;
