import Gallery1 from 'assets/images/jstudio/p1.PNG';
import GalleryT1 from 'assets/images/jstudio/p2.PNG';
import Gallery4 from 'assets/images/jstudio/p3.PNG';
import GalleryT4 from 'assets/images/jstudio/p4.PNG';
import Gallery5 from 'assets/images/jstudio/p5.PNG';
import Gallery6 from 'assets/images/jstudio/p6.PNG';
import Gallery7 from 'assets/images/jstudio/p7.PNG';
import Gallery8 from 'assets/images/jstudio/p8.PNG';

export const photos = [
  {
    original: Gallery1,
    thumbnail: Gallery1,
  },
  {
    original: GalleryT1,
    thumbnail: GalleryT1,
  },
  {
    original: Gallery4,
    thumbnail: Gallery4,
  },
  {
    original: GalleryT4,
    thumbnail: GalleryT4,
  },
  {
    original: Gallery5,
    thumbnail: Gallery5,
  },
  {
    original: Gallery6,
    thumbnail: Gallery6,
  },
  {
    original: Gallery7,
    thumbnail: Gallery7,
  },
  {
    original: Gallery8,
    thumbnail: Gallery8,
  },
];
