import React, { useState } from 'react';

import getQueryValue from 'helpers/getQueryValue';
import { useLocation } from 'react-router';
import HelloSection from 'components/organism/HelloSection';
import WelcomeSection from 'components/organism/WelcomeSection';
import FloatingMusic from 'components/organism/FloatingMusic';
import MainLayout from 'components/organism/Layout';
import WeddingSection from 'components/organism/WeddingSection';
import CovidSection from 'components/organism/Covid19';
import LocationSection from 'components/organism/LocationSection';
import StorySection from 'components/organism/StorySection';
import PhotoSection from 'components/organism/PhotoSection';
import FooterSection from 'components/organism/FooterSection';

const Home = () => {

    const location = useLocation();

    const guestName = decodeURIComponent(getQueryValue(location, 'to') || '');
    const isInvitation = getQueryValue(location, 'type') === 'invitation';
    // const firstName = guestName.replace(/ .*/, '');
    const isAnonymGuest = guestName === '' && !isInvitation;
    const codeLink = getQueryValue(location, 'code') || '';
    const finalTicketLink = `code=${codeLink}&name=${guestName}`;
  
    const [showDetailContent, setShowDetailContent] = useState(false);

    const handleClickDetail = () => {
        setShowDetailContent(true);
      };
    
      const renderDetailContent = () => {
        if (!showDetailContent) return null;
    
        return (
          <>
            <HelloSection isInvitation={isInvitation} />
            <WeddingSection isInvitation={isInvitation} />
            <CovidSection />
            <LocationSection />
            {/* {isInvitation && <CovidSection />} */}
            {/* {isInvitation && <LocationSection />} */}
            <StorySection />
            <PhotoSection />
            {/* <ConfirmationSection guestName={firstName} isInvitation={isInvitation} codeLink={finalTicketLink} /> */}
            <FooterSection isInvitation={isInvitation} />
          </>
        );
      };


      return (
        <MainLayout>
          <WelcomeSection
            guestName={guestName}
            isAnonymGuest={isAnonymGuest}
            isInvitation={isInvitation}
            location={location}
            codeLink={finalTicketLink}
            onClickDetail={handleClickDetail}
          />
          {renderDetailContent()}
          <FloatingMusic />
        </MainLayout>
      );
}

export default Home;