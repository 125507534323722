import Home from 'pages/Home';
import React from 'react';
import { Switch, Route } from 'react-router-dom';


const Routing = () => {
  return(
    <>
      <Switch>
        <Route>
          <Home />
        </Route>
      </Switch>
    </>
  )
};
export default Routing;
