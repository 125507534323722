import ImgPMR from 'assets/images/pmr.jpg';
import ImgTunangan from 'assets/images/bg-tunangan.jpg';
import ImgBandung from 'assets/images/p-bandung-sukabumi.jpg';
// import ImgNdodokLawang from 'assets/images/p-ndodok-lawang.jpg';

export const stories = [
  {
    title: 'Jumpa Pertama ',
    date: 'Oktober 2016',
    description:
      'Pertama kali kami dipertemukan di masa SMK, disebuah acara kegiatan ekstrakulikuler PMR, Gebyar. Sebagai seorang siswa baru, kala itu Torik masih sibuk mengurusi perlengkapan acara, dan saat itu pertemuan pertama dengan Shania terjadi.',
    image: '',
  },
  {
    title: 'Kisah Kasih di Sekolah',
    date: 'Oktober 2016 - 2018',
    description:
      'Sebagai sepasang siswa dan siswi, Shania dan Torik memilih untuk mengisi kisah kasih di sekolahnya dengan mengikuti berbagai kegiatan bersama. Kala itu, Shania adalah siswi Jurusan Pemasaran dan Torik adalah siswa Rekayasa Perangkat Lunak. Banyak kegiatan yang mereka lalui bersama selama masa sekolah sampai lulus pada tahun 2018.',
    image: ImgPMR,
  },
  {
    title: 'Bandung & Sukabumi',
    date: '2018 - 2020',
    description:
      'Tidak selalu bersama, perjuangan dimulai ketika Torik memutuskan untuk bekerja di Bandung sebagai seorang Software Developer. Sembari LDR, Shania juga tetap menjalani kegiatan sebagai seorang karyawan di Sukabumi. Mereka biasa bertemu 2 minggu sekali setiap kali Torik pulang ke Sukabumi ',
    image: ImgBandung,
  },
  {
    title: 'Lamaran',
    date: '26 Mei 2020',
    description:
      'Setelah menjalani LDR, Torik memutuskan untuk berani menyatakan cinta yang lebih serius. Mulai dari acara Lamaran ini, dilanjutkan dengan persiapan pernikahan yang insha Allah akan diselenggarakan pada tanggal 09 Januari 2022.',
    image: ImgTunangan,
  },
];
