import './App.css';
import Root from './Root';
import Routing from './Routing';

const App= () => {
  return (
    <Root>
        <Routing/>
    </Root>
  );
}

export default App;
