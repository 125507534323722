/**
 * Link
 */
 export const GOOGLE_CALENDAR_LINK = `https://calendar.google.com/calendar/event?action=TEMPLATE&dates=20201003T040000Z%2F20201003T060000Z&text=Shania+%26+Torik+Wedding&details=Shania+%26+Torik+Wedding`;
 export const GOOGLE_MAPS_LINK = `https://goo.gl/maps/W6VYV1aKzrt2hxuz6`;
 
 /**
  * Wedding time
  */
 export const EPOCH_START_EVENT = 1641690000;
 export const EPOCH_END_EVENT = 1641718800;
 export const UTC_WEDDING_TIME = '2022-01-09:01:00:00Z';
 