import React, { Fragment } from 'react';
import { bool } from 'prop-types';

import WeddingInfoBox from './WeddingInfoBox';
import { styWrapper } from './styles';

function WeddingSection({ isInvitation }) {

  return (
    <Fragment>
      <div id="fh5co-event" className={styWrapper}>
        <div className="overlay" />
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <span className="bismillah">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</span>
              <h2 className="main-font main-font__wedding">Akad Nikah dan Resepsi Pernikahan</h2>
              <span className="sub-title sub-title__wedding">Insha Allah akan diselenggarakan pada:</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <WeddingInfoBox
                title="Akad Nikah"
                time="Akan dilaksanakan pukul 08.00 WIB "
                date="Minggu, 09 Januari 2022" 
                description="Kediaman mempelai wanita <br/>Nyomplong, Sukabumi"
              />
              <WeddingInfoBox
                title="Resepsi Pernikahan"
                time={isInvitation ? "Sesuai jadwal undangan di e-ticket " : "Akan dilaksanakan setelah akad"}
                date="Minggu, 09 Januari 2022" 
                description="Kediaman mempelai wanita <br/>Nyomplong, Sukabumi"
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

WeddingSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default React.memo(WeddingSection);
